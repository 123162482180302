import { Params } from '@angular/router';
import { Course } from '../course.model';

export interface LessonGroup {
  docId?: string;
  locked?: boolean;
  licenses?: string[];
  items?: LessonItem[];
  order?: number;
  title?: string;
}

export interface LessonItem {
  type?: LessonType;
  link?: string;
  quiz?: string;
  preview?:string;
}

export enum LessonType {
  article = 'article',
  video = 'video',
  assignment = 'assignment',
  quiz = 'quiz',
}

export interface LessonsParams extends Params {
  // course id
  id: string;
  lesson?: string;
  subpage?: string;
}
