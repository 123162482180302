<head>
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css" />
</head>
<div class="home-container">
  <div class="home">
    <div class="welcome-text">
      <table>
        <tr>
          <td align="center">
            <p>Be Prepared For Your Next Interview</p>
            <a mat-raised-button color="courses-btn" routerLink="/courses/interview-prep" rel="noreferrer">
              View Course
            </a>

          </td>
          <td id="image-box">
            <img class="headline-image"
              src="https://res.cloudinary.com/dou1kf4am/image/upload/v1615471228/taskeen_rr6611.webp"
              alt="Taskeen Fatima Basha" />
          </td>
        </tr>
      </table>
    </div>
  </div>
  <!--Benefits-->
  <div class="benefits">
    <div class="benefit" *ngFor="let benefit of benefits">
      <table>
        <tr>
          <td>
            <img class="benefit-icon" src="{{ benefit.icon }}" />
          </td>
          <td class="benefit-text">
            <div class="benefit-title">{{ benefit.title }}</div>
            <div class="benefit-summary">{{ benefit.summary }}</div>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <!--FAQs-->
  <div class="faqs">
    <div class="faq-title">Frequently Asked Questions</div>
    <mat-accordion>
      <mat-expansion-panel class="faq" *ngFor="let faq of faqs">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{faq.question}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-body>{{faq.answer}}</mat-panel-body>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <app-footer></app-footer>
</div>