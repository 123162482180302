import { Pipe, PipeTransform } from '@angular/core';
import { LessonGroup } from './courses/lessons/lesson.model';
import { LicensesService } from './services/licenses.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { IconsService } from './courses/lessons/icons.service';

@Pipe({
  name: 'iconspipe',
})
export class IconsPipe implements PipeTransform {
  constructor(
    public afAuth: AngularFireAuth,
    private licensesService: LicensesService,
    private iconsService: IconsService
  ) {}

  async transform(value: LessonGroup) {
    return await this.iconsService.getSideIcons(value);
  }
}
