import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { RouterModule } from '@angular/router';
import { IconsPipe } from '../icons.pipe';
import { LoginRedirectComponent } from '../login-redirect/login-redirect.component';
import { TestimonialsComponent } from 'src/app/testimonials/testimonials.component';
import { SafePipe } from '../pipes/safe.pipe';
import { TimePipe } from '../time.pipe';
import { CheckoutComponent } from './checkout/checkout.component';
import { EllipsisPipe } from './ellipsis.pipe';
import { FooterComponent } from './footer/footer.component';
import { ShellComponent } from './shell/shell.component';

const pipes = [EllipsisPipe, TimePipe, IconsPipe, SafePipe];
const components = [
  ShellComponent,
  LoginRedirectComponent,
  FooterComponent,
  CheckoutComponent,
  TestimonialsComponent,
];

const modules = [
  ReactiveFormsModule,
  FormsModule,
  CommonModule,
  MatButtonModule,
  MatTooltipModule,
  MatToolbarModule,
  MatIconModule,
  LayoutModule,
  MatSidenavModule,
  MatListModule,
  MatMenuModule,
  FormsModule,
  MatCheckboxModule,
  MatIconModule,
  MatCardModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatSnackBarModule,
  RouterModule,
  FlexLayoutModule,
  MatExpansionModule,
];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [...modules, MatCarouselModule.forRoot()],
  exports: [...components, ...modules, ...pipes],
  providers: [...pipes],
})
export class SharedModule { }
