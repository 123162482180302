// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // serverUrl:
  //   location.host.includes('127.0.0.1') || location.host.includes('localhost')
  //     ? 'http://localhost:5000/skillo-pro/us-central1/server'
  //     : 'https://us-central1-skillo-pro.cloudfunctions.net/server',
  serverUrl:
  location.host.includes('127.0.0.1') || location.host.includes('localhost')
    ? 'http://localhost:5000/skillo-app/us-central1/server'
    : 'https://us-central1-skillo-app.cloudfunctions.net/server',
  firebaseConfig: {
      apiKey: 'AIzaSyAk5MtW28N8fugp6062SYIFNQo8a2oBC_U',
      authDomain: 'skillo-app.firebaseapp.com',
      databaseURL: 'https://skillo-app.firebaseio.com',
      projectId: 'skillo-app',
      storageBucket: 'skillo-app.appspot.com',
      messagingSenderId: '773151839260',
      appId: '1:773151839260:web:4d1ae3085257c873f7fbd9',
      measurementId: "G-BMEBZ5J6QP"

    // apiKey: 'AIzaSyAp-sAgyPt9KDpX9q5Oui9NfEyYNNIv3nc',
    // authDomain: 'skillo-pro.firebaseapp.com',
    // databaseURL: 'https://skillo-pro.firebaseio.com',
    // projectId: 'skillo-pro',
    // storageBucket: 'skillo-pro.appspot.com',
    // messagingSenderId: '248487128880',
    // appId: '1:248487128880:web:d8aba94219c9edc35222f5',
    // measurementId: 'G-4BDY4HVJ3V',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
