import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  public expand: boolean = false;
  public drop: boolean = false;
  benefits = [
    {
      title: 'Expert Videos',
      summary: 'Course created by Amazon, Microsoft and Infosys alumni',
      icon: 'assets/icons/video.svg',
    },
    {
      title: 'Learn By Doing',
      summary: 'Handy assignments to re-inforce the learning',
      icon: 'assets/icons/assignment.svg',
    },
    {
      title: 'Instant Feedback',
      summary: 'Know if your answers are correct or incorrect',
      icon: 'assets/icons/quiz.svg',
    },
    {
      title: 'Bonus Videos',
      summary:
        'Expert videos are added regularly to include insights from industry leaders',
      icon: 'assets/icons/article.svg',
    },
    {
      title: 'Unlimited Access',
      summary:
        'Re-watch the video multiple times to refresh your knowledge before every interview.',
      icon: 'assets/icons/lock.svg',
    },
    {
      title: 'Get Your Dream Job',
      summary:
        'It is never too late to start preparing. Maybe you dream job is just a decision away.',
      icon: 'assets/icons/check.svg',
    },
  ];
  reviews = [
    {
      name: 'Saikiran Kokalla',
      review:
        'By applying this technique, I got selected in Wipro as a UX developer. Thank you!!!',
    },
    {
      name: 'Rithanya Rithu',
      review:
        "I'm a student persuing second year. I had a campus interview with TCS. Using these techniques I got selected. Thank you.",
    },
    {
      name: 'Ankit Rana',
      review:
        "This video helped me alot. Guess what! I cleared my very first interview. I'm in the final year of my graduation and the best thing is that I got my job as a Quality Engineer through pool campus. There were 362 other participants over there and I was among the 14 students that finally got selected just because of this video. Thank you.",
    },
    {
      name: 'Shakib Shaikh',
      review:
        "Your video helped me a lot in clearing an MNC interview. I'm so thankful to you. I would recommend this channel to all those who come from a vernacular background.",
    },
  ];

  faqs = [
    {
      question: 'Who is this course for?',
      answer: "- Fresh Graduates looking for a Job.\n\
      - Professionals looking to change their Job or Career.\n\
      - Professional looking to re - join the workforce after a break.\n\
      - Students applying for higher studies.",
    },
    {
      question: 'What will be taught in this course?',
      answer:
        "1) Tell me about yourself.\n\
        2) What are your strengths and weaknesses?\n\
        3) Tell me about a challenge or conflict you have faced at work and how you’ve dealt with it?\n\
        4) Where do you see yourself in 5 years?\n\
        5) Do you have any questions for us?\n\
        6) Tell me about a time you made a mistake and how you handled it.\n\
        7) Why was there a gap in your employment?\n\
        8) What are your salary expectations?\n\
        9) Why are you leaving your current job?\n\
        10) What is your biggest professional achievement?\n\
      11) Why should we hire you?\n\
      12) Common Guidelines for Onsite and Video Call Interview.",
    },
    {
      question: 'I have more questions, what should I do?',
      answer: "For any other questions, you can drop us a mail at support@skillopro.com"
    },
  ];

}
