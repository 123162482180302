<mat-sidenav-container class="sidenav-container">
  <!-- SIDENAV -->

  <mat-sidenav-content>
    <!-- TOP TOOLBAR-->

    <mat-toolbar>
      <div>
        <span class="logo" routerLink="/">
          <img src="/assets/skillo.svg" style="width: 35px; transform: translate(0px, 8px)" />
          <span style="line-height: 60px"> Skillo </span>
        </span>
      </div>
      <!--
      <span class="fill-space"></span>
      <div *ngIf="!(isHandset$ | async)">
         <a routerLinkActive="topbar-active" routerLink="/courses">Courses</a> 
        <span *ngIf="!(afAuth.authState | async)"> 
          <a *ngIf="!isActive('/login')" mat-raised-button color="active-color" routerLink="/login">Sign in</a>
          <a *ngIf="isActive('/login')" class="topbar-inactive" routerLink="/login">Sign in</a>
        </span>
      </div>

    -->
      <div>
        <button *ngIf="auth.isLoggedIn" style="margin-left: 10px;" mat-icon-button [matMenuTriggerFor]="menu"
          aria-label="profile-btn">
          <img src="{{ auth.user.photoURL }}" title="{{ auth.user.displayName }}" class="profile-img" />
        </button>
        <button *ngIf="!auth.isLoggedIn" style="margin-left: 10px;" mat-icon-button [matMenuTriggerFor]="menu"
          aria-label="profile-btn">
          <img src="/assets/icons/user.svg" style="width: 30px;" title="Sign in" class="profile-img" />
        </button>

        <mat-menu #menu="matMenu">
          <!-- <span *ngIf="auth.isLoggedIn" mat-menu-item routerLink="/user/profile">
            Profile
          </span> -->
          <span *ngIf="!auth.isLoggedIn && !isActive('/login')" mat-menu-item (click)="auth.googleSignin()">
            Login
          </span>
          <span *ngIf="auth.isLoggedIn" mat-menu-item (click)="auth.signOut()">
            Logout
          </span>
        </mat-menu>
      </div>
    </mat-toolbar>

    <!-- TRANSCLUSION -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>