import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';

export interface UserLicense {
  bought?: firebase.firestore.Timestamp;
  expires?: firebase.firestore.Timestamp;
  courseId?: string;
  type?: string;
  planType?: string;
}

export interface UserInterface {
  licenses?: UserLicense[];
}

export function intersect<T>(a: T[], b: T[]) {
  var setB = new Set(b);
  return [...new Set(a)].filter((x) => setB.has(x));
}

@Injectable({
  providedIn: 'root',
})
export class LicensesService {
  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) { }

  licenses: UserLicense[];

  async getUserLicences(uid: string) {
    return new Promise(resolve => {
      if (!uid) {
        // Get the current user's licenses
        // This will not resolve
        // const user = await this.afAuth.authState.toPromise();
        let _this = this
        this.afAuth.onAuthStateChanged(async function (user) {
          if (user) {
            let us = await _this.db
              .collection<UserInterface>('users')
              .doc(user.uid)
              .get()
              .toPromise();
            resolve(us)
          }
        })

        //

      } else {
        // TODO some other user's licenses (?) to maybe see on their profile page
        return null;
      }
    })

  }

  // promiseInstance =

  async boughtCourse(courseId: string) {
    return new Promise<boolean>(resolve => {
      // if (!this.licenses) {
      this.getUserLicences(null).then((_inter: any) => {
        if (!_inter || !_inter.data()) {
          resolve(false)
        }
        let licenses = (_inter.data() as UserInterface)
        if (!licenses) {
          resolve(false)
          return;
        }
        this.licenses = licenses.licenses;
        let found = this.licenses.filter((x) => {
          return x.courseId == courseId && x.expires.toDate() > new Date();
        });
        console.debug('found', found);
        resolve(found.length > 0)
      })
      //}
    })
  }
}
