import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sample-checkout-page',
  templateUrl: './sample-checkout-page.component.html',
  styleUrls: ['./sample-checkout-page.component.scss'],
})
export class SampleCheckoutPageComponent implements OnInit {
  serverUrl = environment.serverUrl;

  randomId() {
    return new Date().getTime();
  }

  constructor() {}

  ngOnInit(): void {
    var button: HTMLButtonElement = document.querySelector('#form-submit');
    //console.log(button);
    button.onclick = function () {
      document.querySelector('form').submit();
    };
  }
}
