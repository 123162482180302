import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(value: number): string {
    if (!value) return null;
    const date = new Date(value * 1000);
    return `${date.getHours()}h ${date.getMinutes()}m`;
  }
}
