import { Injectable } from '@angular/core';
import { LessonType, LessonGroup } from './lesson.model';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  LicensesService,
  UserLicense,
  UserInterface,
} from '../../services/licenses.service';
import { User } from '../../services/User';
import { Observable } from 'rxjs';
import firebase from 'firebase/app';


const IconsBasePath = '/assets/icons/';

const Icons = {
  [LessonType.article]: 'article.svg',
  [LessonType.assignment]: 'assignment.svg',
  [LessonType.quiz]: 'quiz.svg',
  [LessonType.video]: 'video.svg',
};

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  userDetails: Observable<UserInterface>;
  user: User;
  gotIcon: boolean = false;
  icon: Observable<string>;
  state: firebase.User;
  constructor(
    public afAuth: AngularFireAuth,
    private licensesService: LicensesService
  ) {
    // TODO get user's active licenses here once
    // this.licensesService.getUserLicences(null).then(async (x) => {
    //   if (x) {
    //     this.userDetails = x[0];
    //     this.user = new User(await this.userDetails.toPromise());
    //     this.state = await this.afAuth.authState.toPromise();
    //     //console.log('done with user details');
    //   }
    // });
  }

  getIconByLessonType(lessonType: LessonType): string {
    return IconsBasePath + Icons[lessonType];
  }

  async getSideIcons(lesson: LessonGroup) {
    // if (this.gotIcon) return this.icon;
    let icon: string;
    //console.log('Got lesson');
    if (!this.afAuth.authState) {
      if (lesson.locked) icon = 'lock.svg';
      else icon = null;
      //console.log('not logged in');
    } else {
      // TODO check if lesson completed and return check.svg
      if (lesson.locked) {
        if (this.user.canAccess(lesson)) {
          // check if user completed the lesson
          if (this.user.didCompleteLesson(lesson)) {
            icon = 'check.svg';
          } else icon = null;
        } else icon = 'locked.svg';
      }
      //console.log('logged in');
    }

    //console.log(icon);
    return IconsBasePath + icon;
  }
}
