import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss'],
})
export class LoginRedirectComponent implements OnInit {
  @Input() button: boolean = true;

  constructor(public auth: AuthService, private router: Router) { }

  ngOnInit(): void { }

  register() {
    this.router.navigate(['/login'])
  }
}
