<div *ngIf="!auth.isLoggedIn" class="login-options">
  <div class="login-message">You need to Login to see this content.</div>
  <button mat-raised-button (click)="auth.googleSignin()">
    <img src="/assets/google-logo.svg" /> Login with Google
  </button>
</div>
<div *ngIf="auth.isLoggedIn && !auth.isRegistered" class="login-options">
  <div class="login-message">You need to complete your profile to see this content.</div>
  <button mat-raised-button (click)="register()">
    Register
  </button>
</div>