import { AfterViewInit, Component } from '@angular/core';
import { reviews } from './reviews'


@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements AfterViewInit {
  reviews = reviews;
  width: String = reviews.length + "00%";
  currentSlide = 0;
  amountToMove = 0;
  classes = new Array(5).fill("inactive")
  constructor() {
    this.classes[this.currentSlide] = "active"
  }

  ngAfterViewInit() {
    setInterval(() => {
      this.slideTo(this.currentSlide + 1)
    }, 5000);
  }

  public slideTo(i: number) {
    i = i % this.reviews.length;
    this.classes[this.currentSlide] = 'inactive'
    this.classes[i] = "active"
    this.amountToMove = (i * 100) / this.reviews.length
    this.currentSlide = i;
  }

}
