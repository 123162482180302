<div class="container fluid">
  <h1 align="center">Cashfree Test Form</h1>
  <h2 align="center">All the following details will be hidden to the user</h2>
  <form
    id="redirectForm"
    method="post"
    action="{{ serverUrl }}/payment/request"
  >
    <div class="form-group">
      <label>App ID:</label><br /><input
        name="appId"
        value="28027c9640bc71c68ce9db3ae72082"
        placeholder="Enter App ID here (Ex. 123456a7890bc123defg4567)"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>Order ID:</label><br /><input
        name="orderId"
        value="TUF-{{ randomId() }}"
        placeholder="Enter Order ID here (Ex. order00001)"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>Order Amount:</label><br /><input
        name="orderAmount"
        value="300"
        placeholder="Enter Order Amount here (Ex. 100)"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>Order Currency:</label><br /><input
        name="orderCurrency"
        value="INR"
        placeholder="Enter Currency here (Ex. INR)"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>Order Note:</label><br /><input
        name="orderNote"
        value="@item bought by @user"
        placeholder="Enter Order Note here (Ex. Test order)"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>Name:</label><br /><input
        name="customerName"
        value="@user.name"
        placeholder="Enter your name here (Ex. John Doe)"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>Email:</label><br /><input
        name="customerEmail"
        value="useremail@sample.email"
        placeholder="Enter your email address here (Ex. Johndoe@test.com)"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>Phone:</label><br /><input
        name="customerPhone"
        value="9999999999"
        placeholder="Enter your phone number here (Ex. 9999999999)"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>Return URL:</label><br /><input
        name="returnUrl"
        value="{{ serverUrl }}/payment/response"
        placeholder="Enter the URL to which customer will be redirected (Ex. www.example.com)"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>Notify URL:</label><br /><input
        name="notifyUrl"
        placeholder="Enter the URL to get server notificaitons (Ex. www.example.com)"
        class="form-control"
      />
    </div>
    <button id="form-submit" value="Pay" class="btn btn-primary btn-block">
      Submit</button
    ><br /><br /><br /><br />
  </form>
</div>
