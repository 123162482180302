import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-knowledge-page',
  templateUrl: './knowledge-page.component.html',
  styleUrls: ['./knowledge-page.component.scss'],
})
export class KnowledgePageComponent implements OnInit {
  fetched = false;
  error = null;
  //const availablePages: string[] = ["privacy", "about", "contact", "refund", "terms"]
  markdown: string;

  constructor(private actRoute: ActivatedRoute) {}

  private getMarkdown(page: string): string {
    let markdown = 'assets/markdown/error.md';
    let availablePages: string[] = [
      'privacy',
      'about',
      'contact',
      'refund',
      'terms',
    ];
    if (availablePages.includes(page))
      markdown = 'assets/markdown/' + page + '.md';
    return markdown;
  }

  ngOnInit() {
    this.actRoute.paramMap.subscribe((params) => {
      this.markdown = this.getMarkdown(params.get('page'));
    });
  }
  onLoad = (_: string) => (this.fetched = true);
  onError = (e: HttpErrorResponse) => (
    (this.error = e.message), (this.fetched = true)
  );
}
