import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './user/auth.guard';
import { KnowledgePageComponent } from './knowledge-page/knowledge-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginPageComponent } from './user/login-page/login-page.component';
import { SampleCheckoutPageComponent } from './sample-checkout-page/sample-checkout-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'knowledge/:page', component: KnowledgePageComponent },
  { path: 'checkout', component: SampleCheckoutPageComponent },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'courses',
    loadChildren: () =>
      import('./courses/courses.module').then((m) => m.CoursesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      anchorScrolling: 'enabled',
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
