<div class="center">
  <markdown
    emoji
    [src]="markdown"
    (load)="onLoad($event)"
    (error)="onError($event)"
  >
    <p *ngIf="!fetched">Fetching...</p>
    <p *ngIf="true">{{ error }}</p>
  </markdown>
</div>

<app-footer></app-footer>
