<div class="carousel">
    <div class="slides" [ngStyle]="{'width': width, 'transform': 'translateX(-' + amountToMove + '%)'}">
        <div [class]="'review-card slide'+i" *ngFor="let review of reviews; let i = index">
            <div class="review row">
                <div class="column quotes">
                    &ldquo;
                </div>
                <div class="column review-text">
                    {{review.review}}
                </div>
            </div>
            <hr>
            <div class="name-avatar">
                <img *ngIf="review.gender =='male'" src="assets/avatar_male.png" class="avatar">
                <img *ngIf="review.gender =='female'" src="assets/avatar_female.png" class="avatar">
                <div class="name">{{ review.name }}</div>
            </div>
        </div>
    </div>
    <div class="navigation-manual">
        <label (click)="slideTo(i)" [class]="'manual-btn ' + classes[i]"
            *ngFor="let review of reviews; let i = index"></label>
    </div>
</div>