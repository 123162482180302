import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';

import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';

export interface User {
  uid: string;
  name: string;
  phone: string;
  country: string;
  date: Date;
  email: string;
  licenses?: any[];
}

@Injectable({ providedIn: 'root' })
export class AuthService implements OnInit {

  user: firebase.User = null;
  userDoc: User = null;
  isLoggedIn: boolean = false;
  isRegistered: boolean = false;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
  ) {
    this.setStatus()
  }

  ngOnInit() { }

  public loggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  public async googleSignin() {
    const url = this.router.url
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    await this.afAuth.signInWithPopup(provider);
    await this.setStatus()
    // if (!this.isRegistered) {
    //   this.router.navigate(['/login'])
    // }
  }

  public async getUser() {
    const userRef: AngularFirestoreDocument<User> = await this.afs.collection('users').doc(this.user.uid);
    return userRef.valueChanges().pipe(first()).toPromise();
  }

  // public registered() {
  //   if (this.user) {
  //     console.log(!!(this.getUser(this.user.uid)))
  //     return !!(this.getUser(this.user.uid))
  //   }
  //   return false;
  // }

  public async createProfile(form: FormGroup, country_code: string) {
    const data = {
      uid: this.user.uid,
      name: form.get("name").value,
      phone: country_code + form.get("phone").value,
      country: form.get("country").value,
      date: form.get("date").value,
      email: this.user.email,
      licenses: []
    }

    await fetch(`${environment.serverUrl}/user`, {
      mode: "no-cors",
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .catch((err) => {
        console.error(err);
      });
    this.setStatus()
    this.router.navigate(['/'])
  }

  async signOut() {
    await this.afAuth.signOut();
    this.setStatus();
    this.router.navigate(['/'])
  }

  private async setStatus() {
    this.user = await this.loggedIn()
    if (this.user) {
      this.isLoggedIn = true;
      this.userDoc = await this.getUser()
      if (this.userDoc)
        this.isRegistered = true;
      else
        this.isRegistered = false;
    }
    else {
      this.isLoggedIn = false;
      this.isRegistered = false;
    }
  }

}