export let reviews = [
    {
        name: 'Saikiran Kokalla',
        review:
            'By applying this technique, I got selected in Wipro as a UX developer. Thank you!!!',
        gender: 'male',
    },
    {
        name: 'Rithanya Rithu',
        gender: 'female',
        review:
            "I'm a student persuing second year. I had a campus interview with TCS. Using these techniques I got selected. Thank you.",
    },
    {
        name: 'Ankit Rana',
        gender: 'male',
        review:
            "This video helped me alot. Guess what! I cleared my very first interview. I'm in the final year of my graduation and the best thing is that I got my job as a Quality Engineer through pool campus. There were 362 other participants over there and I was among the 14 students that finally got selected just because of this video. Thank you.",
    },
    {
        name: 'Shakib Shaikh',
        gender: 'male',
        review:
            "Your video helped me a lot in clearing an MNC interview. I'm so thankful to you. I would recommend this channel to all those who come from a vernacular background.",
    },
];