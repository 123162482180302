<head>
  <link
    rel="stylesheet"
    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css"
  />
</head>
<section class="footer">
  <div class="sr-container">
    <div class="footer__parent">
      <div class="footer__column--branding">
        <a href="/" class="footer__logo">
          <img
            class="footer__logo-img"
            alt="Skillo logo"
            src="/assets/skillo-orig.svg"
          />
        </a>
        <div class="footer__love">
          Created with
          <span style="font-size: 0.5rem"
            ><i class="fa fa-heart fa-2x"></i
          ></span>
          by The Urban Fight
        </div>
      </div>
      <div class="footer__column">
        <div class="footer__heading">
          <!-- <h3 routerLink="/courses">Upcoming</h3> -->
          <h3>Upcoming</h3>
        </div>
        <ul class="footer__contents">
          <li class="footer__list">
            <a class="footer__link" routerLink="/"> Interview Preparation </a>
          </li>
          <li class="footer__list">
            <a class="footer__link" routerLink="/"> Finance for Beginners </a>
          </li>
          <li class="footer__list">
            <a class="footer__link" routerLink="/"> Spoken English </a>
          </li>
          <li class="footer__list">
            <a class="footer__link" routerLink="/"> Digital Marketing </a>
          </li>
          <li class="footer__list">
            <a class="footer__link" routerLink="/"> Presentation Skills </a>
          </li>
        </ul>
      </div>
      <div class="footer__column">
        <div class="footer__heading">
          <h3>Resources</h3>
        </div>
        <ul class="footer__contents">
          <li class="footer__list">
            <a class="footer__link" routerLink="/knowledge/about"> About Us </a>
          </li>
          <li class="footer__list">
            <a class="footer__link" routerLink="/knowledge/contact">
              Contact Us
            </a>
          </li>
          <li class="footer__list">
            <a class="footer__link" routerLink="/knowledge/terms">
              Terms and Conditions
            </a>
          </li>
          <li class="footer__list">
            <a class="footer__link" routerLink="/knowledge/privacy">
              Privacy Policy
            </a>
          </li>
          <li class="footer__list">
            <a class="footer__link" routerLink="/knowledge/refund">
              Refund Policy
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__column">
        <div class="footer__heading">
          <h3>Follow Us</h3>
        </div>
        <ul class="footer__contents">
          <li class="footer__list">
            <a
              class="footer__link"
              href="https://youtube.com/theurbanfight"
              target="_blank"
              rel="noreferrer"
            >
              <i class="footer__link-icon icon-play"></i>
              Youtube English
            </a>
          </li>
          <li class="footer__list">
            <a
              class="footer__link"
              href="https://youtube.com/skillohindi"
              target="_blank"
              rel="noreferrer"
            >
              <i class="footer__link-icon icon-play"></i>
              Youtube Hindi
            </a>
          </li>

          <li class="footer__list">
            <a
              class="footer__link"
              href="https://linkedin.com/company/skillopro"
              target="_blank"
              rel="noreferrer"
            >
              <i class="footer__link-icon icon-linkedin-logo"></i>
              LinkedIn
            </a>
          </li>
          <li class="footer__list">
            <a
              class="footer__link"
              href="https://twitter.com/skillopro"
              target="_blank"
              rel="noreferrer"
            >
              <i class="footer__link-icon icon-twitter"></i>
              Twitter
            </a>
          </li>
          <li class="footer__list">
            <a
              class="footer__link"
              href="https://www.instagram.com/skillo.pro/"
              target="_blank"
              rel="noreferrer"
            >
              <i class="footer__link-icon icon-instagram"></i>
              Instagram
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div class="copyright__content">
      <p class="m-h-10">
        Copyright <span style="font-size: 1.2rem">&copy;</span>
        2021 The Urban Fight. All Rights Reserved.
      </p>
    </div>
  </div>
</section>
